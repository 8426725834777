import React from "react";
import ThreePieceMedium from "../images/SB_0075-1000.png";
import BuyButtonExternal from "./BuyButtonExternal";

const ProductCard = () => {
  return (
    <div class="m-8 max-w-sm rounded shadow-lg p-25">
      <img
        class="w-full"
        src={ThreePieceMedium}
        alt="ThreePieceMediumBrushSet"
      ></img>
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">
          3 Piece - Medium Bristle Brush Set
        </div>
        <p class="text-gray-700 text-base">
          Provides great all round scrubbing power with medium stiffness
          bristles
        </p>
      </div>
      <div class="px-6 pt-4 pb-4">
        <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          1 x 2" Flat Brush
        </span>
        <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          1 x 4" Flat Brush
        </span>
        <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          1 x 3.5" Dome Brush
        </span>
      </div>
      <div class="pb-4 flex place-content-center">
        <BuyButtonExternal link_dest="https://www.amazon.co.uk/dp/B09GTTQH8D" />
      </div>
    </div>
  );
};

export default ProductCard;
