import React from "react";

const BuyButtonExternal = ({ link_dest }) => {
  return (
    <div class="pb-4 flex place-content-center">
      <a href={link_dest} target="_blank" rel="noreferrer">
        <button className="px-10 py-3 mb-3 bg-yellow-500 rounded-full text-black font-bold hover:bg-yellow-300 transition duration-300 ease-in-out flex items-center animate-pulse">
          <svg
            className="w-6 h-6 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
          BUY NOW
        </button>
      </a>
    </div>
  );
};

export default BuyButtonExternal;
