import React from "react";
import FloorScrub from "../images/floor-scrub-1000.png";
import ArtyFourInch from "../images/SB_0088-1000.png";
import ArtyDome from "../images/SB_0090-1000.png";
import ArtySmall from "../images/SB_0092-1000.png";
import DomeTaps from "../images/dome-taps-1000.png";
import SmallTiles from "../images/small-tiles-1000.png";
import ShopButton from "./ShopButton";

const ProductIntroGrid = () => {
  return (
    <div className="flex flex-row justify-center items-center">
      <div className="bg-white h-screen flex flex-col justify-center items-center">
        <div className="grid gap-5 grid-cols-3 justify-center items-center pl-3 pr-3 lg:max-w-5xl">
          <img
            src={ArtyFourInch}
            alt="four-inch"
            className="rounded-lg ring-2 ring-green-200"
          />
          <img
            src={ArtyDome}
            alt="dome"
            className="rounded-lg ring-2 ring-green-200"
          />
          <img
            src={ArtySmall}
            alt="small"
            className="rounded-lg ring-2 ring-green-200"
          />
          <img
            src={FloorScrub}
            alt="floor-scrub"
            className="rounded-lg ring-2 ring-green-400"
          />
          <img
            src={DomeTaps}
            alt="dome-taps"
            className="rounded-lg ring-2 ring-green-400"
          />
          <img
            src={SmallTiles}
            alt="small-tiles"
            className="rounded-lg ring-2 ring-green-400"
          />
        </div>
        <div className="center-content py-10">
          <h2 className="text-2xl mb-2">Steggy Loves a Good Scrub</h2>
          <p className="mb-2">Great on all sorts of hard-wearing surfaces</p>
        </div>
        <ShopButton link_dest="/products" />
      </div>
    </div>
  );
};

export default ProductIntroGrid;
