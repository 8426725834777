import React from "react";
import Hero from "./components/Hero";
import LogoIntro from "./components/LogoIntro";
import ProductIntroGrid from "./components/ProductIntroGrid";

export default function Home() {
  return (
    <div>
      <Hero />
      <section id='logo-intro'>
        <LogoIntro />
      </section>
      <section id='product-grid'>
        <ProductIntroGrid />
      </section>
    </div>
  );
}
