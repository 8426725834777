import React from "react";
import {Link} from 'react-router-dom'

const ShopButton = ({ link_dest }) => {
  return (
    <div>
      <Link
        className="lg:py-6 py-5 lg:px-10 px-8 bg-yellow-500 rounded-full lg:text-3xl text-xl hover:bg-yellow-300 transition duration-300 ease-in-out flex items-center animate-none"
        to={link_dest}
      >
        <svg
          className="w-3 h-3 w-6 h-6 md:mr-2 mr-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
        Shop Now{" "}
      </Link>
    </div>
  );
};

export default ShopButton;
