import React from "react";
import Logo from "../images/Logo-Transparency.png";
import AnchorLink from 'react-anchor-link-smooth-scroll'

const LogoIntro = () => {
  return (
    <div className="flex flex-row justify-center items-center">
      <div className="bg-white h-screen flex flex-col justify-center items-center">
        <div className="m-8 w-3/4 rounded shadow-lg p-25">
          <img src={Logo} alt="logo" className="lg:max-h-screen" />
        </div>
        <div className="py-4">
          <h2 className="text-2xl mb-2">
            The might of a Stegosaurus packed into a Drill Brush
          </h2>
          <p className="mb-2">Grab your drill and you're good to go!</p>
        </div>
        <AnchorLink href="#product-grid">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 animate-pulse"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
            />
          </svg>
        </AnchorLink>
      </div>
    </div>
  );
};

export default LogoIntro;
