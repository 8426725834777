import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'

const LearnButton = () => {
  return (
    <div>
      <AnchorLink
        className="lg:py-6 py-5 lg:px-10 px-8 bg-green-500 rounded-full lg:text-3xl text-xl hover:bg-green-300 transition duration-300 ease-in-out flex items-center animate-none"
      href="#logo-intro"
      >
        Learn More{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="md:ml-2 ml-1 w-3 h-5 w-5 h-7 animate-pulse"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
          />
        </svg>
      </AnchorLink>
    </div>
  );
};

export default LearnButton;
