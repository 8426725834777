import React from "react";
import ProductCard from "./components/ProductCard";

export default function Products() {
  return (
    <div class="flex place-content-center">
      <ProductCard/>
    </div>
  );
}
