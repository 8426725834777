import './App.css';
import NavBar from './components/NavBar'
import Home from './Home'
import Products from './Products'
import { BrowserRouter as Router, Route } from "react-router-dom";
import Footer from './components/Footer';
import HowTo from './HowTo';

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Route exact path="/" component={Home} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/how" component={HowTo} />
      </Router>
      <Footer />
    </div>
  );
}

export default App;
