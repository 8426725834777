import React from "react";
import ShopButton from "./ShopButton";
import LearnButton from "./LearnButton";

const Hero = () => {
  return (
    <div className="flex flex-row justify-center items-center">
      <div className="bg-white h-screen flex flex-col justify-center items-center">
        <h1 className="lg:text-9xl md:text-7xl text-5xl font-black mb-10">
          SCRUBBING JUST GOT EASY
        </h1>
        <div className='flex flex-row justify-center items-center gap-5'>
          <ShopButton link_dest="/products" />
          <LearnButton />
        </div>
      </div>
    </div>
  );
};

export default Hero;
