import React from "react";
import Warning from "./images/warning.png";
import InfoGraphic from "./images/infographic_dims_logo.png";
import Action from "./images/EC5Q9956.png";

const HowTo = () => {
  return (
    <>
      <div className="pt-4 pb-4 m-5 bg-white flex flex-col justify-center items-center border-2 border-solid">
        <img
          src={InfoGraphic}
          alt="infographic"
          className="max-w-xs md:max-w-md"
        />
        <p className="m-3 max-w-prose font-light">
          Our brushes are designed with ease of use in mind
        </p>
        <p className="m-3 max-w-prose font-light">
          Each and every StegosaurBrush is fitted with a 6mm Quick Change Hex
          Shank for rapid install and removal from your drill.
        </p>
      </div>
      <div className="pt-4 pb-4 m-5 bg-white flex flex-col justify-center items-center border-2 border-solid">
        <img src={Warning} alt="warning" className="max-w-xs md:max-w-md" />
        <h1 className="m-3 font-medium uppercase">⚠️ Please Take Care</h1>
        <p className="m-3 max-w-prose font-normal">
          Whilst our brushes are simple to use, you need to be careful when
          using them.
        </p>
        <ol className="m-3 max-w-prose font-light italic">
          <li>
            Always test on an inconspicuous area before letting your
            StegosaurBrush loose!
          </li>
          <li>Ensure you keep your drill at a safe distance from water.</li>
          <li>
            Never use StegosaurBrush on your skin or hair (or anywhere on your
            body)
          </li>
          <li>
            Keep your hands a safe distance away from StegosaurBrush when in
            motion
          </li>
        </ol>
      </div>
      <div className="pt-4 pb-4 m-5 bg-white flex flex-col justify-center items-center border-2 border-solid">
        <img src={Action} alt="warning" className="max-w-xs md:max-w-md" />
        <h1 className="m-3 font-medium uppercase">
          🧽 Look After Your Brushes
        </h1>
        <p className="m-3 max-w-prose font-normal">
          You can rest assured that your StegosaurBrush is tough, but it's still
          important to look after it.
        </p>
        <p className="m-3 max-w-prose font-light">
          Clean your brushes after use.
        </p>
        <p className="m-3 max-w-prose font-light">
          Also note the stiffness of your brush option and ensure you aren't
          over-working it. For example, using a medium stiffness brush on very
          hard surfaces such as concrete may reduce the life of your brush.
        </p>
      </div>
    </>
  );
};

export default HowTo;
