import React from "react";

const Footer = () => {
  return (
    <div className="flex flex-col justify-center items-center md:h-16 h-32 bg-black text-white font-thin">
      <div>
        <p>Copyright © 2021 StegosaurBrush. All rights reserved.</p>
      </div>
      <div>
        <p>The StegosaurBrush logo is a registered trademark of Burns Innovations ®</p>
      </div>
    </div>
  );
};

export default Footer;
